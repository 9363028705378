<template>
  <div class="content">
    <div class="prod">
      <img
        class="tag"
        src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/sys_config/20220615_144902_62a980debc7c7.png"
        alt="标签"
      />
      <div class="qc">
        <img class="qcCode" :src="qcCode" alt="分享码" />
      </div>
      <div class="info">
        <p>商家分享</p>
      </div>
      <div class="discount" v-if="info === 'true'">
        <p>
          {{ user.title }} 满
          <span>{{ activity.coupon[0].full_money }}</span> 减
          <span>{{ activity.coupon[0].money }}</span> 的优惠券
        </p>
      </div>
      <div class="qc">
        <img
          class="bg"
          src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/sys_config/20220615_150716_62a98524ba211.png"
          alt="tag"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'share',
  data() {
    return {
      info: false,
      qcCode: '',
      activity: this.$state.activity,
      user: this.$state.user,
    }
  },
  created() {
    this.info = false
    if (this.$route.params.info) {
      this.info = this.$route.params.info
    }
    this.$http('/merchant/share').then((res) => {
      this.qcCode = res.data.url
    })
  },
}
</script>
<style lang="less" scoped>
.content {
  height: 100vh;
  padding: 32px;
  background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
  p {
    margin: 0;
    padding-top: 5%;
  }
  .prod {
    background: #ffffff;
    height: 75%;
    position: relative;
    border-radius: 16px;
    .tag {
      width: 55.25px;
      height: 50.12px;
      padding: 8px;
    }
    .qc {
      text-align: center;
      margin-top: -20px;
    }
    .qcCode {
      width: 200px;
      height: 200px;
    }
    .info {
      text-align: center;
      font-size: 15px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
    }
    .discount {
      text-align: center;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      span {
        color: #f56f4d;
      }
    }
    .bg {
      width: 218px;
      height: 218px;
    }
  }
}
</style>
